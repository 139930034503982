import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const CDMS_STUDY_SCHEDULE_EMAIL_CODE: {
  CDMS_STUDY_SCHEDULE_EMAIL_NOT_FOUND: MvnCodeBE;
  CDMS_STUDY_SCHEDULE_EMAIL_LIMIT_EXCEED: MvnCodeBE;
  CDMS_STUDY_SCHEDULE_EMAIL_INVALID_TIME: MvnCodeBE;
} = {
  CDMS_STUDY_SCHEDULE_EMAIL_NOT_FOUND: {
    status: 404,
    ko: "요청하신 Study Schedule Email 알림 정보를 찾을 수 없습니다.",
    en: "Requested study schedule email notification could not be found.",
  },
  CDMS_STUDY_SCHEDULE_EMAIL_LIMIT_EXCEED: {
    status: 400,
    ko: "알림은 최대 {maxLimitCount}개까지 추가 가능합니다.",
    en: "You can only add up to {maxLimitCount} notifications.",
  },
  CDMS_STUDY_SCHEDULE_EMAIL_INVALID_TIME: {
    status: 400,
    ko: "메일 발송 시간은 현재 시간보다 이전 시간으로 설정할 수 없습니다.",
    en: "The send time cannot be earlier than the current time.",
  },
};

MvnCodeInitiate(CDMS_STUDY_SCHEDULE_EMAIL_CODE);
