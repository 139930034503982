import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const SAFETY_PRESET_CODE: {
  PRESET_MOVE_INVALID_SAME_DESTINATION: MvnCodeBE;
  PRESET_FOLDER_INVALID_MAX_DEPTH: MvnCodeBE;
  PRESET_FOLDER_MOVE_INVALID_CHILDREN_DESTINATION: MvnCodeBE;
  PRESET_FOLDER_DELETE_INVALID_NOT_EMPTY: MvnCodeBE;
} = {
  PRESET_MOVE_INVALID_SAME_DESTINATION: {
    status: 400,
    ko: "이전과 동일한 위치로는 이동할 수 없습니다.",
    en: "You cannot move to the same location as before.",
  },
  PRESET_FOLDER_INVALID_MAX_DEPTH: {
    status: 400,
    ko: "Folder는 최대 {depth} depth까지 생성할 수 있습니다.",
    en: "Folders can be created up to {depth} depth.",
  },
  PRESET_FOLDER_MOVE_INVALID_CHILDREN_DESTINATION: {
    status: 400,
    ko: "지정한 폴더의 하위 폴더로는 이동시킬 수 없습니다.",
    en: "You cannot move to children of a folder.",
  },
  PRESET_FOLDER_DELETE_INVALID_NOT_EMPTY: {
    status: 400,
    ko: "비어있는 폴더만 삭제할 수 있습니다.",
    en: "Only empty folder can be deleted.",
  },
};

MvnCodeInitiate(SAFETY_PRESET_CODE);
