import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const CDMS_STUDY_DW_LANG: {
  CDMS_STUDY_DW_BLANK_CRF_OPTION_VERTICAL_SCHEDULE: MvnCodeFE;
  CDMS_STUDY_DW_BLANK_CRF_OPTION_HORIZONTAL_SCHEDULE: MvnCodeFE;
  CDMS_STUDY_DW_BLANK_CRF_OPTION_PAGE_SIZE_LABEL: MvnCodeFE;
  CDMS_STUDY_DW_BLANK_CRF_OPTION_PAGE_SIZE_A4: MvnCodeFE;
  CDMS_STUDY_DW_BLANK_CRF_OPTION_PAGE_SIZE_CDISC: MvnCodeFE;
  CDMS_STUDY_DW_BLANK_CRF_OPTION_UNIQUE_LABEL: MvnCodeFE;
  CDMS_STUDY_DW_BLANK_CRF_OPTION_UNIQUE_DESCRIPTION: MvnCodeFE;
  CDMS_STUDY_DW_EXPORT_CRF_EXTRACT_MODE_ECOA_LABEL: MvnCodeFE;
  CDMS_STUDY_DW_EXPORT_CRF_EXTRACT_MODE_ECOA_DESC: MvnCodeFE;
  CDMS_STUDY_DW_EXPORT_CRF_EXTRACT_MODE_INCLUDE_ECOA_LABEL: MvnCodeFE;
  CDMS_STUDY_DW_EXPORT_CRF_EXTRACT_MODE_INCLUDE_ECOA_DESC: MvnCodeFE;
  CDMS_STUDY_DW_EXPORT_CRF_EXTRACT_MODE_ONLY_ECOA_LABEL: MvnCodeFE;
  CDMS_STUDY_DW_EXPORT_CRF_EXTRACT_MODE_ONLY_ECOA_DESC: MvnCodeFE;
  CDMS_STUDY_DW_EXPORT_INCLUDE_NON_CRF_LABEL: MvnCodeFE;
  CDMS_STUDY_DW_AUDIT_TRAIL_CATEGORY_REQUIRED: MvnCodeFE;
} = {
  CDMS_STUDY_DW_BLANK_CRF_OPTION_VERTICAL_SCHEDULE: {
    ko: "Schedule Table을 세로 방향으로 출력",
    en: "Print Schedule Table as Portrait orientation",
  },
  CDMS_STUDY_DW_BLANK_CRF_OPTION_HORIZONTAL_SCHEDULE: {
    ko: "Schedule Table을 가로 방향으로 출력",
    en: "Print Schedule Table as Landscape orientation",
  },
  CDMS_STUDY_DW_BLANK_CRF_OPTION_PAGE_SIZE_LABEL: {
    ko: "용지 크기",
    en: "Paper Size",
  },
  CDMS_STUDY_DW_BLANK_CRF_OPTION_PAGE_SIZE_A4: {
    ko: "A4 Size (8.27 x 11.69 inches)",
    en: "A4 Size (8.27 x 11.69 inches)",
  },
  CDMS_STUDY_DW_BLANK_CRF_OPTION_PAGE_SIZE_CDISC: {
    ko: "CDISC Compatible (8.50 x 11.00 inches)",
    en: "CDISC Compatible (8.50 x 11.00 inches)",
  },
  CDMS_STUDY_DW_BLANK_CRF_OPTION_UNIQUE_LABEL: {
    ko: "Unique CRF 출력",
    en: "Export Unique CRF",
  },
  CDMS_STUDY_DW_BLANK_CRF_OPTION_UNIQUE_DESCRIPTION: {
    ko: "중복되는 페이지는 제외하고 각 CRF 페이지를 출력합니다.",
    en: "Export each CRF page. (Duplicate pages are excluded)",
  },
  CDMS_STUDY_DW_EXPORT_CRF_EXTRACT_MODE_ECOA_LABEL: {
    ko: "eCOA CRF 출력",
    en: "Export eCOA CRF",
  },
  CDMS_STUDY_DW_EXPORT_CRF_EXTRACT_MODE_ECOA_DESC: {
    ko: "eCOA CRF 페이지를 출력합니다.",
    en: "Export eCOA CRF page.",
  },
  CDMS_STUDY_DW_EXPORT_CRF_EXTRACT_MODE_INCLUDE_ECOA_LABEL: {
    ko: "eCOA CRF 페이지를 포함",
    en: "Include eCOA CRF page",
  },
  CDMS_STUDY_DW_EXPORT_CRF_EXTRACT_MODE_INCLUDE_ECOA_DESC: {
    ko: "eCOA CRF 페이지를 포함하여 출력합니다.",
    en: "Export include eCOA CRF page.",
  },
  CDMS_STUDY_DW_EXPORT_CRF_EXTRACT_MODE_ONLY_ECOA_LABEL: {
    ko: "eCOA CRF 페이지만 출력",
    en: "Export only eCOA CRF page",
  },
  CDMS_STUDY_DW_EXPORT_CRF_EXTRACT_MODE_ONLY_ECOA_DESC: {
    ko: "eCOA CRF 페이지만 별도로 출력합니다.",
    en: "Export only eCOA CRF page separately.",
  },
  CDMS_STUDY_DW_EXPORT_INCLUDE_NON_CRF_LABEL: {
    ko: "Non-CRF 포함",
    en: "Include Non-CRF Data",
  },
  CDMS_STUDY_DW_AUDIT_TRAIL_CATEGORY_REQUIRED: {
    ko: "Category를 선택해 주세요.",
    en: "Please select a category.",
  },
};

MvnCodeInitiate(CDMS_STUDY_DW_LANG);
