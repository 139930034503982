import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const SAFETY_PREFILLED_FORM_CODE: {
  PREFILLED_FORM_INVALID_NAME_MAX_LENGTH: MvnCodeBE;
  PREFILLED_FORM_INVALID_NAME_DUPLICATED: MvnCodeBE;
  PREFILLED_FORM_INVALID_PARENT_PRESET_NOT_EXIST: MvnCodeBE;
  PREFILLED_FORM_INVALID_DICTIONARY_VERSION_DIFFERENT: MvnCodeBE;
  PREFILLED_FORM_CREATE_FAILED_INVALID_CRF: MvnCodeBE;
  PREFILLED_FORM_COPY_FAILED_INVALID_CRF: MvnCodeBE;
  PREFILLED_FORM_UPDATE_FAILED_INVALID_CRF: MvnCodeBE;
  PREFILLED_FORM_LOAD_FAILED_INVALID_CRF: MvnCodeBE;
} = {
  PREFILLED_FORM_INVALID_NAME_MAX_LENGTH: {
    status: 400,
    ko: "이름은 최대 {length}자까지 입력할 수 있습니다.",
    en: "Name can be up to {length} characters.",
  },
  PREFILLED_FORM_INVALID_NAME_DUPLICATED: {
    status: 409,
    ko: "이미 사용되고 있는 prefiiled form name입니다. (name: {name})",
    en: "Prefilled form name is duplicated. (name: {name})",
  },
  PREFILLED_FORM_INVALID_PARENT_PRESET_NOT_EXIST: {
    status: 400,
    ko: "존재하지 않는 preset입니다. (id: {id})",
    en: "The requested preset does not exist. (id: {id})",
  },
  PREFILLED_FORM_INVALID_DICTIONARY_VERSION_DIFFERENT: {
    status: 400,
    ko: "Prefilled Form과 {target}의 Dictionary 버전이 일치하지 않습니다.",
    en: "Dictionary version of Prefilled Form and {target} does not match.",
  },
  PREFILLED_FORM_CREATE_FAILED_INVALID_CRF: {
    status: 400,
    ko: "Prefilled Form을 생성할 수 없는 Crf입니다.",
    en: "Unable to create Prefilled Form with invalid Crf.",
  },
  PREFILLED_FORM_COPY_FAILED_INVALID_CRF: {
    status: 400,
    ko: "유효하지 않은 Crf의 Prefilled Form을 복제할 수 없습니다.",
    en: "Unable to copy Prefilled Form of invalid Crf.",
  },
  PREFILLED_FORM_UPDATE_FAILED_INVALID_CRF: {
    status: 400,
    ko: "유효하지 않은 Crf의 Prefilled Form {field}을(를) 수정할 수 없습니다.",
    en: "Unable to modify Prefilled Form {field} of invalid Crf.",
  },
  PREFILLED_FORM_LOAD_FAILED_INVALID_CRF: {
    status: 400,
    ko: "유효하지 않은 Crf의 Prefilled Form을 적용할 수 없습니다.",
    en: "Unable to load Prefilled Form of invalid Crf.",
  },
};

MvnCodeInitiate(SAFETY_PREFILLED_FORM_CODE);
