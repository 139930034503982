import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const CDMS_STUDY_REPORT_LANG: {
  CDMS_STUDY_REPORT_GO_TO_CRF_BUTTON: MvnCodeFE;
} = {
  CDMS_STUDY_REPORT_GO_TO_CRF_BUTTON: {
    ko: "CRF로 이동",
    en: "Go to CRF",
  },
};

MvnCodeInitiate(CDMS_STUDY_REPORT_LANG);
